<template>
    <div class="form-wrap">
        <div class="logo">
            <img src="../assets/images/bentley_logo_a-c1@2x.png"  alt="" />
        </div>
        <div class="title">賓利經銷商培訓報名</div>
        <div class="form-item">
            <span>經銷商</span>
            <div class="input-wrap">
                <select v-model="dealerName" placeholder="請選擇經銷商名稱" class="select-wrap">
                    <option :value="item.dealerName" :key="item.dealerName" v-for="item in dealerList" >{{toTraditional(item.dealerName)}}</option>
                </select>
            </div>
        </div>
        <div class="form-item">
            <span>崗位</span>
            <div class="input-wrap">
                <select v-model="jobRole" placeholder="請選擇崗位" class="select-wrap">
                    <option :value="item" :key="item" v-for="item in jobRoleList" >{{toTraditional(item)}}</option>
                </select>
            </div>
        </div>
        <div class="form-item">
            <span>GRP ID</span>
            <div class="input-wrap">
                <input placeholder="請輸入GRP ID" v-model="hubId" disabled="true" type="text" @blur="grpidBlur">
            </div>
        </div>
        <div class="form-item">
            <span>姓名</span>
            <div class="name-wrap">
                <div class="input-wrap">
                    <input placeholder="姓" v-model="surname" type="text">
                </div>
                <div class="input-wrap">
                    <input placeholder="名" v-model="firstName" type="text">
                </div>
            </div>
        </div>
        <div class="form-item">
            <span>手機號碼</span>
            <div class="input-wrap">
                <input placeholder="請輸入手機號碼" v-model="phone" type="text">
            </div>
        </div>
        <div class="form-item">
            <span>郵箱</span>
            <div class="input-wrap">
                <input placeholder="請輸入郵箱" v-model="email" type="text">
            </div>
        </div>
        <div class="form-item">
            <span>加入賓利<br>品牌時間</span>
            <div class="input-wrap hiredate-wrap" @click="showPicker = true">
                <span v-if="!hiredate" style="margin-left: 5px">加入賓利的時間</span>
                <strong v-if="hiredate" style="margin-left: 5px">{{hiredateText}}</strong>
            </div>
        </div>
        <div class="form-item">
            <span>從事汽車<br>行業年限</span>
            <div class="input-wrap">
                <select v-model="industryYears" placeholder="請選擇年限" class="select-wrap">
                    <option :value="item" :key="item" v-for="item in industryYearsList" >{{item}}</option>
                </select>
            </div>
        </div>
        <div class="submit-btn" @click="handleSubmit">下一步</div>
        <van-popup v-model="showPicker" round position="bottom">
            <van-datetime-picker
                v-model="currentDate"
                type="year-month"
                title="選擇年月"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="confirmDatetime"
                @cancel="() => showPicker = false"
            />
        </van-popup>
    </div>
</template>
<script>
import { DatetimePicker, Popup, Toast, Dialog  } from 'vant';
import moment from 'moment';
import {Landing} from '@/network'
import { toTraditional } from '@/utils'

export default {
    data() {
        return {
            industryYearsList: ['2年以下', '2-5年', '5-10年', '10-15年', '15年以上'],
            jobRoleList: ['总经理', '销售经理', '销售顾问', '销售支持', '售后服务经理', '服务顾问', 'CRM专员', '市场经理', '市场专员',
 '易手车经理', '保修专员', '技师', '钣喷技师-钣金', '钣喷技师-喷漆', '其它'],
//             jobRoleList: ['總經理', '銷售經理', '銷售顧問', '銷售支持', '售後服務經理', '服務顧問', 'CRM專員', '市場經理', '市場專員',
//  '易手車經理', '保修專員', '技師', '鈑噴技師-鈑金', '鈑噴技師-噴漆', '其它'],
            hubId: sessionStorage.getItem('hubId') || null, // 员工编号
            phone: sessionStorage.getItem('phone') || null, // 手机号码
            minDate: new Date(1990, 0, 1),
            maxDate: moment().startOf('month').toDate(),
            currentDate: new Date(),
            showPicker: false,
            dealerName: null, // 经销商
            jobRole: null, // 岗位
            surname: null, // 姓
            firstName: null, // 名
            email: null, // E-Mail
            hiredate: null, // 入职年月
            industryYears: null, // 从事汽车行业年限
            dealerList: null,
            toTraditional
        }
    },
    computed: {
        hiredateText() {
            if (!this.hiredate) return
            return moment(this.hiredate).format('YYYY年M月')
        }
    },
    components: {
        vanDatetimePicker: DatetimePicker,
        vanPopup: Popup,
    },
    async created() {
        const {data} = await Landing.getDealer({pageNum:1, pageSize:9999})
        if (data) {
            this.dealerList = data
        }
        const userInfo = await Landing.getUserInfo({phone: this.phone})
        if (!userInfo) return
        this.dealerName = userInfo.dealerName  // 经销商
        this.jobRole = userInfo.jobRole  // 岗位
        this.surname = userInfo.surname  // 姓
        this.firstName = userInfo.firstName  // 名
        this.email = userInfo.email  // E-Mail
        this.hiredate = userInfo.hiredate  // 入职年月
        this.phone = userInfo.phone
        this.hubId = userInfo.hubId
        this.industryYears = userInfo.industryYears // 从事汽车行业年限
    },
    methods: {
        handleSubmit() {
          if (!this.dealerName) return Toast('經銷商不能為空')
          if (!this.jobRole) return Toast('崗位不能為空')
          if (!this.hubId) return Toast('GRP ID不能為空')
          if (!this.surname) return Toast('姓不能為空')
          if (!this.firstName) return Toast('名不能為空')
          if (!this.phone) return Toast('手機號碼不能為空')
          if (!this.email) return Toast('E-Mail不能為空')
          if (!this.hiredate) return Toast('加入賓利品牌時間不能為空')
          if (!this.industryYears) return Toast('從事汽車行業年限不能為空')
          this.hubId = this.hubId.toUpperCase()
          Landing.getByHubId({hubId: this.hubId}).then(userInfo =>{
            if (userInfo && userInfo.phone != this.phone) {
              Dialog.alert({className: 'dialog-alert-font-class','message': '您輸入的GRP ID已被其他員工註冊，請您與BMC培訓助理進行聯繫BMC_trainingassistant@gpstrategies.com'})
            } else {
              Landing.checkWhite({hubId: this.hubId}).then(data => {
                console.warn('data', data)

                Landing.submitForm({
                  dealerName: this.dealerName,
                  jobRole: this.jobRole,
                  hubId: this.hubId,
                  surname: this.surname,
                  firstName: this.firstName,
                  phone: this.phone,
                  email: this.email,
                  hiredate: this.hiredate,
                  industryYears: this.industryYears,
                }).then(() => {
                  if (data.isWhiteList != 1) { //不在白名单,请联系上级
                    Dialog.alert({
                      allowHtml: true,
                      className: 'dialog-alert-font-class',
                      messageAlign: 'left',
                      title: '非常抱歉',
                      message: '由於以下原因，您暫時不能進行預約<br><br>•GRP ID 輸入有誤<br>•未完成 eAcademy 相關課程<br>•入職時長尚未達到6個月<br>•其他原因<br><br>如有任何問題，請您與BMC培訓助理進行聯繫<br>BMC_trainingassistant@gpstrategies.com'
                    })
                  } else {
                    sessionStorage.setItem('phone', this.phone)
                    sessionStorage.setItem('hubId', this.hubId)
                      if (this.jobRole == '總經理' || this.jobRole == '总经理') {
                          this.$router.replace({name: 'GMFirst'})
                      } else {
                        this.$router.replace({name: 'AfterForm'})
                      }
                  }
                })
              })
            }
          })
        },
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            }
            return val;
        },
        confirmDatetime(time) {
            this.showPicker = !this.showPicker
            this.hiredate = moment(time).toISOString()
        },
        // async isRegister() {
        //   Landing.getByHubId({hubId: this.hubId}).then(userInfo =>{
        //     if (userInfo && userInfo.phone != this.phone) {
        //       return true
        //     }
        //   })
        //   return false
        // },
        grpidBlur() {
          if (this.hubId) {
            Landing.getByHubId({hubId: this.hubId}).then(userInfo =>{
              if (userInfo && userInfo.phone != this.phone) {
                Dialog.alert({className: 'dialog-alert-font-class', 'message': '您輸入的GRP ID已被其他員工註冊，請您與BMC培訓助理進行聯繫BMC_trainingassistant@gpstrategies.com'})
              }
            })
          }
        },
    }
}
</script>

<style lang="scss">
.dialog-alert-font-class .van-button__text {
  font-family: 'Bentley', 'simhei', RegularEng, Helvetica, 'Avenir', Arial, sans-serif;
}
</style>

<style lang="scss" scoped>
.form-wrap {
    width: 100%;
    height: 100%;
}
.logo {
    width: 375px;
    height: 80px;
    background-color: white;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    img {
        width: 84px;
        height: 42px;
        margin: 20px 20px;
    }
}
.title{
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    background-color: rgba(0,50,32,0.7);
    margin-bottom: 20px;
}
.form-item {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 0 25px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-bottom: 15px;
    span {
        color: rgba(50, 50, 50, 1)
    }
    .input-wrap {
        width: 220px;
        height: 35px;
        background-color: #fff;
        box-sizing: border-box;
        border: 1px solid rgba(200, 200, 200, 1);
        // border-radius: 4px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
            width: 100%;
            line-height: 32px;
            height: 32px;
            font-size: 14px;
            color: #000;
            padding: 0px 5px;
            &::placeholder {
                color: #999;
                font-size: 14px;
            }
        }
        &.hiredate-wrap {
            span {
                width: 100%;
                line-height: 25px;
                 color: #999;
                font-size: 14px;
            }
            strong {
                width: 100%;
                line-height: 32px;
                height: 32px;
                font-size: 14px;
                color: #000;  
                font-weight: normal;
            }
        }
        .select-wrap {
            width: 100%;
            height: 100%;
            border: 0;
            color: #000;
            background-color: #fff;
        }
    }
    .name-wrap {
        width: 220px;
        height: 26px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .input-wrap {
            width: 105px;
        }
    }
}
.submit-btn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,50,32,0.7);
    font-size: 16px;
    color: #fff;
    bottom: 0;
    margin-top:28px;
}
.pop {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 0 45px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}
</style>